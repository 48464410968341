<script>


/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card class="rounded-lg" :loading="loading" :disabled="loading">
      <v-card-title class="headline primary white--text">
        Create Policy
      </v-card-title>
      <v-card-text class="pt-3">
        <v-form ref="memberForm">
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="name">
                First Name<span class="red--text">*</span>


              </label>
              <v-text-field v-model="newMember.firstName" outlined dense />
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="midlename">
                Middle Name
              </label>
              <v-text-field v-model="newMember.midleName" outlined dense />
            </v-col>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="lastname">
                Last Name<span class="red--text">*</span>


              </label>
              <v-text-field v-model="newMember.lastName" class="rounded" outlined dense></v-text-field>
            </v-col>

          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="dateofbirth">Date of Birth
                <span class="red--text">*</span>
              </label>
              <DobPicker  @dateDOB="birthDate = $event" />
             
            </v-col>

            <v-col cols="12" sm="4" class="px-1">
              <label for="phone">Phone </label>
              <v-text-field v-model="newMember.homePhone" :rules="[rules.phone1]" prepend-inner-icon="mdi-phone"
                autocomplete="tel" outlined dense />
            </v-col>
          </v-row>
          <v-row no-gutters>

            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="gender">Gender

              </label>
              <v-select :items="genders" item-text="label" item-value="value" v-model="newMember.isMale" outlined
                dense></v-select>
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="legal">Legal Status </label>
              <v-select :items="legalstatus" v-model="newMember.legalStatus" item-text="label" item-value="value" dense
                outlined></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="cernatur">ID Number </label>
              <br />

              <v-text-field outlined v-model="newMember.idNumber" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="cernatur">Company<span class="red--text">*</span>
              </label>
              <br />
              <v-select :items="companies" v-model="companySelect" :loading="loadingRefer" item-text="name"
                item-value="id" outlined dense></v-select>
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label :class="'font-weight-medium'" for="cernatur">Company State<span class="red--text">*</span>
              </label>
              <br />
              <v-autocomplete :items="companyStates" :loading="loadingCompanyStates"
                :disabled="companySelect == null || companyStates.length == 0" v-model="newMember.companyStateId"
                item-text="state.name" item-value="state.id" outlined dense></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="source">Binder Pay </label>
              <v-radio-group v-model="newMember.binderDay" class="mt-0" mandatory row dense hide-details>
                <v-radio :value="true" :label="$t('Yes')" />
                <v-radio :value="false" :label="$t('No')"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="cernatur">Policy Number
              </label>
              <br />

              <v-text-field v-model="newMember.polizeNumber" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="source">Effective Date
              </label>
              <EffectivePicker  :creating="true" @dateDOB="effectiveDate = $event" />
             
            </v-col>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium">Metal Plan
              </label>
              <br />

              <v-select :items="metalplan" item-text="label" item-value="value" v-model="newMember.policyPlan" outlined
                dense></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium">Premium </label>
              <v-text-field outlined dense v-model="newMember.premium"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="source">Members </label>
              <v-text-field v-model="members" outlined dense></v-text-field></v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-alert class="mx-4" type="error" :value="!validForm||!validDate">
       {{!validDate ? "Invalid Date of Birth" :' All fields with ( * ) are required'}}
      </v-alert>
      <v-divider class="mb-3" />

      <v-card-actions class="justify-end">
        <v-btn color="error" :disabled="loading" width="100" dark depressed rounded @click="cancel">
          Cancel
        </v-btn>
        <!--      <v-btn
        color="grey"
        :disabled="loading"
        width="100"
        dark
        depressed
        rounded
        @click="$refs.patientForm.reset()"
      >
        Reset
      </v-btn> -->
        <v-btn class="ml-3" color="primary" :disabled="loading || !validForm||!validDate" :loading="loading" width="100" depressed
          rounded @click="create">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import {
  notificationError,
  notifyError,
  notifySuccess,
} from "@/components/Notification";
import rules from "@/components/account/rules";
import EffectivePicker from '@/components/MaDatePicker/EffectivePicker.vue';

import DobPicker from '@/components/MaDatePicker/DobPicker.vue';
import { getAPI } from "@/api/axios-base";
import { mapActions, mapMutations, mapState } from "vuex";
import moment from 'moment';
export default {
  name: "create-members",
  components: { DobPicker,EffectivePicker },
  data() {
    return {
      rules: {
        required: rules.required,
        email: rules.email,
        zip: rules.isZipCode,
        phone: rules.isPhone,
        phone1: rules.isPhone1,
      },
      loading: false,
      loadingCompanyStates: false,
      companySelect: null,
      companyStates: [],
      effectiveDate: moment().add(1, "month").startOf("month"),
      members:1,
      birthDate:'',
      newMember: {
        firstName: "",
        lastName: "",
        midleName: "",        
        homePhone:'',
        birthDate: '',
        isMale: false,
        legalStatus: "CITIZEN",
        idNumber: "",            
        isAplicant: true,       
        clientUuid: "",         
        companyId: null,
        companyStateId: null,
        policyPlan: "SILVER",
        polizeNumber: "",
        binderDay:false,
        effectiveDate: moment().add(1, "month").startOf("month"),
        premium:0,
        membersByMonth: [0,0,0,0,0,0,0,0,0,0,0,0],        
      },
      genders: [
        { label: "Female", value: false },
        { label: "Male", value: true },
      ],
      legalstatus: [
        { label: "Citizen", value: "CITIZEN" },
        { label: "Permanent Resident", value: "PERMANENT_RESIDENT" },
        { label: "Temporal Resident", value: "TEMPORAL_RESIDENT" },
      ],
      metalplan: [
        { label: "Bronze", value: "BRONZE" },
        { label: "Silver", value: "SILVER" },
        { label: "Gold", value: "GOLD" },
      ],
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loadingRefer", "companies"]),
    validWorkPrimary() {
      const path = /^[1-9][0-9]{9}$/;
      if (path.test(this.newMember.workPhone)) {
        return true;
      }
      return false;
    },
    validDate() {
      if (this.birthDate == "" || this.birthDate == null) {
        return false;
      }
      const d = moment(this.birthDate);
      const now = moment();
      if (d.isBefore(now)) {
        return true;
      }
      return false;
    },
    
    validForm() {     
        if (
          this.newMember.firstName==null||
          this.newMember.firstName==""||
          this.newMember.lastName==null||
          this.newMember.lastName==""||
          this.birthDate==null||
          this.birthDate==""||
          this.newMember.companyId == null ||
          this.newMember.companyId == "" ||
          this.newMember.companyStateId == null ||
          this.newMember.companyStateId == "" 
        ) {
          return false;
        }
        return true;
      
    },
  },
  watch: {
    companySelect(val) {
      if (val != null) {
        this.newMember.companyId = val;
        this.getCompanyState(val);
      }
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListCompaniesNom"]),
    ...mapMutations("crmMedicFormModule", ["mutPatientDetails"]),
    cancel() {
      const uuid = this.$route.params.uuid;
      this.$router.push(`/healths/details/${uuid}`);
    },
    create() {
      let body = Object.assign({}, this.newMember);
      const uuid = this.$route.params.uuid;
      body.clientUuid = uuid;
      body.birthDate = moment(this.birthDate).toISOString()
      if (body.homePhone) {
        body.homePhone = "+1" + body.homePhone;
      }
      if (body.premium) {
        body.premium =Number(body.premium);
      }
      if (this.members) {
        const m = Number(moment().format('MM'))
        for (let index = m-1; index < body.membersByMonth.length; index++) {
          body.membersByMonth[index]=Number(this.members)          
        } 
      }
     
      if (this.effectiveDate) {
        body.effectiveDate = moment(this.effectiveDate).toISOString();
      }
      if (body.premium==0||body.premium==null||body.premium==''||body.premium==undefined) {
        delete body.premium;
      }

      body = this.cleanNull(body);
      this.loading = true;
      getAPI
        .post("/members/create", body)
        .then((res) => {
          this.loading = false;
          notifySuccess("Policy has been create");
          this.mutPatientDetails(null);
          this.$router.push(`/healths/details/${uuid}`);
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    getCompanyState(id) {
      this.loadingCompanyStates = true;
      getAPI
        .post("/company-state-price/filterList", { companyId: id })
        .then((res) => {
          this.loadingCompanyStates = false;
          this.companyStates = res.data;
        })
        .catch((error) => {
          this.loadingCompanyStates = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  mounted() {
    this.actListCompaniesNom();
  },
};
</script>
<style lang="scss" scoped>
.cerofnat {
  padding-top: 2px !important;
}
</style>
